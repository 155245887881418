<template>
  <div>
    <span class=""><strong>Ultimo conceito: </strong>{{ opinion != null? opinion.grade: '' }}</span>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "OpinionEnrollmentRequest",
  props: ["requestId", "op"],

  data() {
    return {
      opinion: null,
    };
  },

  created() {
    if (this.requestId != null)
      this.getAsyncOpinionEnrollmentRequest(this.requestId);
  },

  watch: {
    requestId(newValue) {
      this.getAsyncOpinionEnrollmentRequest(newValue);
    },

    op(newValue){
      this.opinion = newValue.grade;
    }
  },

  methods: {
    ...mapActions("opinionEnrollmentRequest", ["ActionGetOpinionByRequestId"]),

    async getAsyncOpinionEnrollmentRequest(id) {
      try {
        let res = await this.ActionGetOpinionByRequestId(id);
        if(res.status == 200)
          this.opinion = res.body.grade;
      } catch (e) {
        console.error(e);
      } finally {
        this.disable = false;
      }
    },
  },
};
</script>

<style>
</style>